import React from "react"
import PropTypes from "prop-types"

const SkipButton = ({ onClick, className }) => (
  <button
    className={"pv3 gray bn br0 pointer db bg-transparent " + className}
    onClick={onClick}
  >
    Skip this step
  </button>
)

SkipButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
}

export default SkipButton

import React, { useMemo } from "react"
import { useSelector } from "react-redux"
import { filterIncompatiblePlugs } from "../../helpers/IncompatibilityHelper"
import Dropdown from "../../components/Dropdown"

type CardIds = {
  headsetId: number
  headsetSeriesId: number
  bottomCableId: number
  pttSeriesId: number
  topCableId: number
  cableWithoutPttId: number
  pttId: number
}

type Plug = {
  id: number
  name: string
}

type PlugsDropdownProps = {
  cableId: number
  onChange: (plug: { label: string; value: string }) => void
  withPTT: boolean
}

const PlugsDropdown = ({
  cableId,
  onChange,
  withPTT = true,
}: PlugsDropdownProps) => {
  // @ts-expect-error: State does not have a type
  const plugs = useSelector((state) => state.plugs.items)
  // @ts-expect-error: State does not have a type
  const cartIds = useSelector((state) => state.cartIds) as CardIds
  const incompatibilities = useSelector(
    // @ts-expect-error: State does not have a type
    (state) => state.incompatibilities.items,
  )

  const getIncompatiblePlugs = useMemo(() => {
    return filterIncompatiblePlugs(
      plugs,
      incompatibilities,
      cartIds,
      cableId,
      withPTT,
    )
  }, [plugs, incompatibilities, cartIds, cableId, withPTT])

  const getCompatiblePlugs = useMemo(() => {
    return plugs.filter((ptt: { id: string }) => {
      return !getIncompatiblePlugs.some(
        (incompatiblePlug: { id: string }) => incompatiblePlug.id == ptt.id,
      )
    })
  }, [getIncompatiblePlugs])

  const mappedPlugs = useMemo(() => {
    return getCompatiblePlugs.map((plug: Plug) => {
      return { id: plug.id, label: plug.name, value: plug.id }
    })
  }, [getCompatiblePlugs])

  return (
    <Dropdown
      className="plug-select"
      onChange={onChange}
      options={mappedPlugs}
      defaultName='Choose connector'
    />
  )
}

export default PlugsDropdown

import React from "react"

type CardProps = {
  name: string
  imageUrl: string
  description: string
  children?: React.ReactNode
}
const Card = ({ name, imageUrl, description, children }: CardProps) => {
  return (
    <article className="mb-6 flex flex-col w-full pointer border-b-2 border-slate-100">
      <div
        className="flex items-center justify-center h-80"
        style={{ backgroundColor: "#F5F6F6" }}
      >
        <img src={imageUrl} alt={name} className="p-2 max-h-full" />
      </div>
      <div className="py-5 lg:py-10">
        <p className="mb-2 lg:mb-4 font-semibold">{name}</p>
        <p className="mb-4" style={{ whiteSpace: "pre" }}>
          {description}
        </p>
        <div>{children}</div>
      </div>
    </article>
  )
}

export default Card

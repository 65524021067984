import React from "react"
import PropTypes from "prop-types"

import { connect } from "react-redux"
import { bindActionCreators } from "redux"
import { getCart } from "../cartSelector"
import { resetCart } from "../actions"
import { push } from "connected-react-router"
import { PrimaryButton } from "../components/Buttons"

const startOver = (resetCart, push, location) => (e) => {
  resetCart()
  push("/" + location.search)
}

const CartBar = ({ cart, resetCart, push, location }) => (
  <div className="w-100 relative pa4-l pa5 bg-near-white w-third-l dib fixed-l h-100">
    <div className="f3 b tc mv5"> Your Headset</div>
    <div className="dark-gray db flex items-center mv2">
      <div className="dib w-100 w-50-ns">Headset</div>
      <div className="dib w-100 w-50-ns lh-copy tr">
        {cart.headset ? cart.headset.name : ""}
      </div>
    </div>
    <div className="dark-gray db flex items-center mv2">
      <div className="dib w-100 w-50-ns">PTT</div>
      <div className="dib w-100 w-50-ns lh-copy tr">
        {cart.ptt ? cart.ptt.name : ""}
      </div>
    </div>
    <div className="dark-gray db flex items-center mv2">
      <div className="dib w-100 w-50-ns">Top cable</div>
      <div className="dib w-100 w-50-ns lh-copy tr">
        {cart.topCable ? cart.topCable.name : ""}
      </div>
    </div>
    <div className="dark-gray db flex items-center mv2">
      <div className="dib w-100 w-50-ns">Bottom cable</div>
      <div className="dib w-100 w-50-ns lh-copy tr">
        {cart.bottomCable ? cart.bottomCable.name : ""}
      </div>
    </div>
    <div className="dark-gray db flex items-center mv2">
      <div className="dib w-100 w-50-ns">Cable Without PTT</div>
      <div className="dib w-100 w-50-ns lh-copy tr">
        {cart.cableWithoutPtt ? cart.cableWithoutPtt.name : ""}
      </div>
    </div>
    <div className="dark-gray db flex items-center mv2">
      <div className="dib w-100 w-50-ns">Plug</div>
      <div className="dib w-100 w-50-ns lh-copy tr">
        {cart.plug ? cart.plug.name : ""}
      </div>
    </div>
    <div className="dark-gray db flex items-center mv2">
      <div className="dib w-100 w-50-ns">Plug configuration</div>
      <div className="dib w-100 w-50-ns lh-copy tr">
        {cart.plugConfiguration ? cart.plugConfiguration.name : ""}
      </div>
    </div>
    <div className="mv4">
      <PrimaryButton
        onClick={startOver(resetCart, push, location)}
        className="mv2"
        text="Start over"
      />
    </div>
  </div>
)

const mapStateToProps = (state) => ({
  cart: getCart(state),
})

CartBar.propTypes = {
  cart: PropTypes.object.isRequired,
  resetCart: PropTypes.func.isRequired,
  push: PropTypes.func.isRequired,
  location: PropTypes.object,
}

const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ push, resetCart }, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(CartBar)
